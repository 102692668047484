import { motion, useInView } from 'framer-motion';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const OurInsights = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`content-list bg-pattern lg:pt-100 pt-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInView || hasPlayed ? 'show' : 'hidden'}
        variants={container}
        className="container-fluid fade-ani"
      >
        {module?.heading && (
          <motion.div
            variants={item}
            className="title-black max-w-[1000px] ml-auto mr-auto"
          >
            <h3>{module.heading}</h3>
          </motion.div>
        )}
        {module?.points?.length > 0 && (
          <motion.div variants={item}>
            <ul className="global-list grid grid-cols-2 max-w-[1000px] ml-auto mr-auto gap-x-20 gap-y-5 ipad:grid-cols-2 mdscreen:grid-cols-1 mt-20">
              {module?.points?.map((item, index) => (
                <li key={index.toString()}>
                  {item?.description && parse(item?.description)}
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </motion.div>
    </section>
  );
};

export default OurInsights;
export const NavigationContent_OurInsightsFragment = graphql`
  fragment NavigationContent_OurInsightsFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_OurInsights {
    id
    extraClass
    hideSection
    heading
    points {
      description
    }
  }
`;
export const OurInsightsFrament = graphql`
  fragment OurInsightsFragment on WpExperience_Experiencecontent_Experience_OurInsights {
    id
    extraClass
    hideSection
    heading
    points {
      description
    }
  }
`;
