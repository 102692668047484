import PageLayout from '../components/Layout/Page';
import Seo from '../components/Seo';
import Experience from '../modules/Experience';
import { graphql } from 'gatsby';
import React, { useEffect } from 'react';

const ExperienceTemplate = (props) => {
  const modules = props?.data?.experience?.experienceContent?.experience || [];
  const subExperiences = props?.data?.experience?.subExperiences?.nodes || [];

  useEffect(() => {
    document.body.classList.remove('nav-menu-open');
    document.body.classList.remove('header-sticky');
    document.body.classList.remove('video-modal-open');
    document.body.classList.remove('blog-single-custom');
  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data.experience.seo} />
      <Experience
        modules={modules}
        location={props.location}
        subExperiences={subExperiences}
      />
    </PageLayout>
  );
};

export default ExperienceTemplate;
export const ExperienceById = graphql`
  query experienceById($id: String) {
    experience: wpExperience(id: { eq: $id }) {
      id
      ...seoExperienceFragment
      subExperiences: wpChildren {
        nodes {
          ... on WpExperience {
            id
            title
            uri
            featuredImage {
              node {
                altText
                gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 500)
                mediaItemUrl
              }
            }
            excerpt
          }
        }
      }
      ...ExperienceModulesFragment
    }
  }
`;
