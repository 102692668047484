import Banner from '../components/Banner';
import Gallery from '../components/Gallery';
import GuestFeedbacks from '../components/GuestFeedbacks';
import ImageWithContent from '../components/ImageWithContent';
import OurInsights from '../components/OurInsights';
import PopularDestination from '../components/PopularDestination';
import RelatedTravelStyle from '../components/RelatedTravelStyle';
import RelatedTripInspiration from '../components/RelatedTripInspiration';
import SimpleContent from '../components/SimpleContent';
import SimpleFullImageWithContent from '../components/SimpleFullImageWithContent';
import SliderImageWithContent from '../components/SliderImageWithContent';
import SubExperienceListing from '../components/SubExperienceListing';
import { graphql } from 'gatsby';
import React from 'react';

const Experience = ({ modules, location, subExperiences }) => {
  const components = {
    Banner,
    SimpleContent,
    Gallery,
    SimpleFullImageWithContent,
    RelatedTripInspiration,
    PopularDestination,
    RelatedTravelStyle,
    SubExperienceListing,
    ImageWithContent,
    OurInsights,
    GuestFeedbacks,
    SliderImageWithContent,
  };

  return (
    <>
      {modules
        ? modules
            .filter(
              (module) => !module?.hideSection || module?.hideSection === 'no'
            )
            .map((module, i) => {
              const moduleName = module.__typename.replace(
                'WpExperience_Experiencecontent_Experience_',
                ''
              );
              return (
                components[moduleName] &&
                React.createElement(components[moduleName], {
                  key: i,
                  module,
                  location,
                  subExperiences,
                })
              );
            })
        : null}
    </>
  );
};

export default Experience;
export const ExperienceModulesFragment = graphql`
  fragment ExperienceModulesFragment on WpExperience {
    experienceContent {
      experience {
        __typename
        ...ExperienceBannerFragment
        ...ExperienceSingleSimpleContentFragment
        ...ExperienceGalleryFragment
        ...ExperienceSingleSimpleFullImageWithContentFragment
        ...ExperienceSingleRelatedTripInspirationFragment
        ...ExperienceSinglePopularDestinationFragment
        ...SubExperienceRelatedTravelStyleFragment
        ...SubExperienceListingFragment
        ...ExperienceImageWithContentFragment
        ...OurInsightsFragment
        ...ExperienceGuestFeedbacksFragment
        ...ExperienceSliderImageWithContentFragment
      }
    }
  }
`;
