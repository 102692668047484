import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const SubExperienceListing = ({ module, subExperiences }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const aniItem = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);

  if (!subExperiences?.length || subExperiences?.length === 0) {
    return null;
  }

  return (
    <section
      className={`small-img-grid no-pattern lg:py-120 py-30${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={isInView || hasPlayed ? 'show' : 'hidden'}
          variants={container}
          className="flex flex-wrap lg:mx-minus-30 mx-0 gap-y-14 mdscreen:gap-y-8"
        >
          {subExperiences?.length > 0 &&
            subExperiences.map((item) => (
              <motion.div
                variants={aniItem}
                className="lg:w-4/12 w-full lg:px-15 px-0 fade-ani"
                key={item?.id}
              >
                <Link to={item.uri}>
                  <div className="ig-bx">
                    <ImageOpt
                      imgData={item.featuredImage?.node.mediaItemUrl}
                      imgAlt={item.featuredImage?.node?.altText}
                      imgClass="w-full"
                      width={432}
                      height={420}
                    />
                    <div className="ig-info pr-30 pt-20">
                      <div className="title-black fade-ani">
                        {item?.title && <h6>{item.title}</h6>}
                        {item?.excerpt && (
                          <div className="content ">{parse(item.excerpt)}</div>
                        )}
                        {item?.uri && (
                          <div className="btn-custom flex flex-wrap items-center space-x-8 mt-20 ">
                            <span className="button btn-red-border">
                              Explore Experience
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </section>
  );
};

export default SubExperienceListing;

export const SubExperienceListingFragment = graphql`
  fragment SubExperienceListingFragment on WpExperience_Experiencecontent_Experience_SubExperienceListing {
    id
    extraClass
    hideSection
    pageSize
  }
`;
